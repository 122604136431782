import React, { useState } from 'react';
import { TextField, TextFieldTheme } from 'wix-ui-tpa/cssVars';
import { Search } from '@wix/wix-ui-icons-common/on-stage';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import { st, classes } from '../../Widget.st.css';
import constants from '../../../../../constants';
import { CategoriesPosition } from '../../../../../enums';
import { useEnvironment } from '@wix/yoshi-flow-editor';

const SearchWrapper = (props: {
  onSearchChange: (searchTerm: string) => void;
}) => {
  const { onSearchChange } = props;
  const { isMobile } = useEnvironment();
  const settings: ISettingsContextValue = useSettings();
  const { text, show } = settings.get(settingsParams.searchBar);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e: any) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearchChange(value);
  };
  const categoriesPosition: CategoriesPosition = settings.get(
    settingsParams.categoriesPosition,
  );
  const sideCategoriesPosition =
    categoriesPosition === constants.DISPLAY_CATEGORIES.SIDE;
  return (
    <>
      {show && (
        <TextField
          className={st(classes.search, {
            isSideCategoriesPosition: sideCategoriesPosition,
            mobile: isMobile,
          })}
          value={searchTerm}
          theme={TextFieldTheme.Line}
          onChange={handleSearchChange}
          placeholder={text}
          suffix={<Search />}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSearchChange(searchTerm);
            }
          }}
        />
      )}
    </>
  );
};

export default SearchWrapper;
