import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { Text } from 'wix-ui-tpa/cssVars';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import settingsParams from '../../../settingsParams';
import { AppContext } from '../SettingsProvider/SettingsProvider';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';
import { st, classes } from '../../Widget.st.css';
import SelectedLayout from './SelectedLayout';
import { FAQService } from '../../../../../utils/FAQService';

const QuestionsWrapper = (props: {
  searchTerm?: string;
  questions: QuestionEntry[];
}) => {
  const { searchTerm, questions } = props;
  const settings: ISettingsContextValue = useSettings();

  const { selectedCategory, setFirstQuestionId, instance } =
    useContext(AppContext);
  const { t } = useTranslation();
  const api = useMemo(() => new FAQService(instance), [instance]);
  const [questionsList, setQuestionsList] = useState(questions || []);
  const filteredQuestions: QuestionEntry[] = questionsList;
  const firstLoad = useRef(true);

  const debouncedSearch = useMemo(
    () =>
      debounce(async (term) => {
        if (term) {
          const { questionEntries } = await api.getSearchTerm({ term });
          setQuestionsList(questionEntries as []);
        } else {
          const listQuestionsResponse = await api.getListQuestions({
            categoryId: selectedCategory,
          });
          const { questionEntries } = listQuestionsResponse;
          setQuestionsList(questionEntries as []);
        }
      }, 500),
    [api, selectedCategory],
  );

  useEffect(() => {
    if (!firstLoad.current) {
      debouncedSearch(searchTerm);
    }
    firstLoad.current = false;
  }, [searchTerm, debouncedSearch]);

  const showFirstAnswer: boolean = settings.get(settingsParams.openFirstAnswer);
  const assignFirstQuesionId = useCallback(() => {
    if (setFirstQuestionId) {
      if (!showFirstAnswer) {
        setFirstQuestionId(undefined);
      } else if (filteredQuestions.length > 0 && filteredQuestions[0].id) {
        setFirstQuestionId(filteredQuestions[0].id);
      }
    }
  }, [filteredQuestions, setFirstQuestionId, showFirstAnswer]);
  useEffect(() => {
    assignFirstQuesionId();
  }, [assignFirstQuesionId]);
  return filteredQuestions?.length > 0 ? (
    <SelectedLayout filteredQuestions={filteredQuestions} />
  ) : (
    <div className={st(classes.Body)}>
      <Text>{t('widget.search.tab.no.results.found')}</Text>
    </div>
  );
};

export default QuestionsWrapper;
