import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';
import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import SocialWrapper from '../SocialWrapper/SocialWrapper';
import RCV from './RCV';
import { st, classes } from './styles.st.css';
import { DraftJSObject } from '../../helpers/Interfaces';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';
import { AccordionCSS } from './Interfaces';
import settingsParams from '../../../settingsParams';

const ListOneColumn = ({
  filteredQuestions,
}: {
  filteredQuestions: QuestionEntry[];
}) => {
  const settings: ISettingsContextValue = useSettings();
  const { gap } = settings.get(settingsParams.spacing);
  const itemAppearance = settings.get(settingsParams.itemAppearance);
  const customStyle = {
    '--item-gap': `${gap / 2}px`,
  } as AccordionCSS;

  return (
    <div style={customStyle} className={st(classes.listLayout)}>
      {filteredQuestions.map((item: QuestionEntry) => {
        const { id, question, draftjs } = item;
        const parsedDraftjs: DraftJSObject = JSON.parse(draftjs!);
        return (
          <div
            className={st(classes.listWrapperBorder, { itemAppearance })}
            key={id!}
          >
            <div className={st(classes.listWrapperAccordion)}>
              <Text key={id!} id={id!} className={st(classes.question)}>
                {question!}
              </Text>
              <Text key={id!} id={id!} className={st(classes.answer)}>
                {parsedDraftjs && <RCV convertAnswerToJSON={parsedDraftjs} />}
              </Text>
            </div>
            <SocialWrapper questionId={id!} />
          </div>
        );
      })}
    </div>
  );
};

export default ListOneColumn;
