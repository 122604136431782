import React, { FC, useCallback, useEffect, useState } from 'react';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { pauseHeightChanges } from '@wix/yoshi-flow-editor/wix-height-updater';
import { TPAComponentsProvider } from 'wix-ui-tpa/cssVars';
import HeaderWrapper from './Components/HeaderWrapper/HeaderWrapper';
import TabsWrapper from './Components/Tabs/TabsWrapper';
import { AppContext } from './Components/SettingsProvider/SettingsProvider';
import settingsParams from '../settingsParams';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { useSettings } from '@wix/tpa-settings/react';
import { st, classes } from './Widget.st.css';
import QuestionsWrapper from './Components/QuestionsWrapper/QuestionsWrapper';
import SearchWrapper from './Components/SearchWrapper/SearchWrapper';
import { CategoriesPosition } from '../../../enums';
import constants from '../../../constants';
import { AccordionCSS } from './Components/QuestionsWrapper/Interfaces';

export interface ControllerProps {
  instance: string;
  baseURL: string;
  questionId: string;
  api: any;
  initialData: any;
}
const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const { instance, baseURL, questionId, api: appAPI, initialData } = props;
  const settings: ISettingsContextValue = useSettings();
  const { isMobile } = useEnvironment();
  const textAlignment = settings.get(settingsParams.textAlignment);
  const isRTL = textAlignment === 'right';
  const { vertical, horizontal } = settings.get(settingsParams.spacing);
  const customStyle = {
    '--item-top-bottom-padding': `${vertical}px`,
    '--item-left-right-padding': `${horizontal}px`,
  } as AccordionCSS;
  const categoriesPosition: CategoriesPosition = settings.get(
    settingsParams.categoriesPosition,
  );
  const { DISPLAY_CATEGORIES, DISPLAY_CONTENT } = constants;
  const sideCategoriesPosition = categoriesPosition === DISPLAY_CATEGORIES.SIDE;
  const topCategoriesPosition = categoriesPosition === DISPLAY_CATEGORIES.TOP;
  const showCategories =
    settings.get(settingsParams.displayContent) ===
    DISPLAY_CONTENT.SPLIT_INTO_CATEGORIES;
  const [selectedCategory, setSelectedCategory] = useState<
    (string | null | undefined)[] | undefined | string | null
  >(initialData?.selectedCategory);
  const [searchTerm, setSearchTerm] = React.useState('');
  const handleSearchChange = useCallback((term: string) => {
    setSearchTerm(term);
  }, []);
  const { show: showSearch } = settings.get(settingsParams.searchBar);
  const [firstQuestionId, setFirstQuestionId] = useState<string | undefined>(
    questionId,
  );

  useEffect(() => {
    pauseHeightChanges();
  }, []);
  return (
    <TPAComponentsProvider value={{ rtl: isRTL, mobile: isMobile }}>
      <AppContext.Provider
        value={{
          instance,
          baseURL,
          selectedCategory,
          setSelectedCategory,
          firstQuestionId,
          setFirstQuestionId,
          questionId,
          appAPI,
        }}
      >
        <div
          data-hook="faq-root"
          dir={isRTL ? 'rtl' : 'ltr'}
          style={customStyle}
          className={st(classes.container, {
            mobile: isMobile,
            isSideCategoriesPosition: sideCategoriesPosition,
            isTopCategoriesPosition: topCategoriesPosition,
            hideSearch: !showSearch,
            hideCategories: !showCategories,
          })}
        >
          <HeaderWrapper />
          <TabsWrapper
            searchTerm={searchTerm}
            validLists={initialData.validLists}
          />
          <QuestionsWrapper
            questions={initialData.questionEntries}
            searchTerm={searchTerm}
          />
          <SearchWrapper onSearchChange={handleSearchChange} />
        </div>
      </AppContext.Provider>
    </TPAComponentsProvider>
  );
};

export default Widget;
